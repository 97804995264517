body {
  font-family: 'Open Sans', sans-serif;
  color: #445360;
  background: #fafafa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.title-color{
  color: #1275fa;
}

.title-font{
  font-family:'Roboto Slab', 'Helvetica', sans-serif;
}

.bgcolors{
  background: linear-gradient(2deg, rgba(53,119,242, 1), rgba(8,240,201, 1));
}

/* Vertical Centering */
.v-center{
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}


/* HOME */
.panel-sidebar {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: #FFF;
}


.text-grey{ color: #CCC; }

/* SLIDERS */
input[type=range] {
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 12px;
  margin-top:10px;
  cursor: pointer;
  background: #DDD;
  border-radius: 5px;
}
input[type=range]::-webkit-slider-thumb {
  position: relative;
  display: inline-block;
  padding: 15px;
  margin-top: -10px;
  border-radius: 50%;
  background: #3577f2;
  cursor: pointer;
  -webkit-appearance: none;
  z-index: 100;
}
input[type=range]:focus::-webkit-slider-runnable-track {}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 12px;
  margin-top:10px;
  cursor: pointer;
  background: #DDD;
  border-radius: 5px;
}
input[type=range]::-moz-range-thumb {
  position: relative;
  display: inline-block;
  padding: 15px;
  margin-top: -10px;
  border-radius: 50%;
  background: #3577f2;
  cursor: pointer;
  z-index: 100;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 12px;
  margin-top:10px;
  cursor: pointer;
  background: #DDD;
  border-radius: 5px;
}
input[type=range]::-ms-fill-lower {}
input[type=range]::-ms-fill-upper {}
input[type=range]::-ms-thumb {
  position: relative;
  display: inline-block;
  padding: 15px;
  margin-top: -10px;
  border-radius: 50%;
  background: #3577f2;
  cursor: pointer;
  z-index: 100;
}
input[type=range]:focus::-ms-fill-lower {}
input[type=range]:focus::-ms-fill-upper {}

input.init[type=range],
input.init[type=range],
input.init[type=range]{
  -webkit-animation: pulse 1s infinite;
          animation: pulse 1s infinite;
}






/* Navigation */
#main-menu{
  font-family:'Roboto Slab', 'Helvetica', sans-serif;
  border-bottom: 0;
  padding: 0;
  background: #FFF;;
}
#main-menu.affix{ 
  background: rgba(255, 255, 255, .8); 
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
}
#main-menu a.navbar-brand{
  color: #333;
}

#main-menu .navbar-brand {
  font-size: 30px;
  line-height: 1;
  font-weight: 300;
}
#main-menu .navbar-brand {
  float: left;
  height: 60px;
  padding: 15px;
}

#main-menu .nav > li > a,
.topbar .btn-link{
  font-size: 20px;
  line-height: 20px;
  font-weight: 300;
  padding: 20px;
  color: rgba(255,255,255,.9);
  border: none;
  background: transparent;
}
#main-menu .nav > li > a, 
.topbar .btn-link{
  color: #333;
}

#main-menu .nav > li.active a,
#main-menu .nav > li.active:hover a,
#main-menu .nav > li:hover a{
  color: #FFF;
  background: rgba(53, 119, 242, 1);
}

#module-name, .navbar-brand a{ color: #333; }

.secondary-bar{ 
  border-left: 3px solid rgba(53, 119, 242);
  background: rgba(68, 70, 72, .1);
}






/* Menu Modal */
#menu-modal{
  background: rgba(71, 83, 101, 1);
}


/* Slide menu */
#slide-menu ul{
  background: #FFF;
}
#slide-menu ul li > a,
#slide-menu ul li > span{
  color: #1275fa;
  border-left: 3px solid #FFF;
}

#slide-menu ul li.active > a,
#slide-menu ul li.active > span,
#slide-menu ul li > a:hover,
#slide-menu ul li > span:hover{
  color: #444648;
  border-left: 3px solid #1275fa;
  background: #ececec;
}

#slide-menu ul .profile-info{ color: #1275fa; padding: 20px 0 10px 0; }
#slide-menu ul .close-menu{ color: #FFF; }


/* Game Setup */
#shortlink{ color: #1275fa; }

.btn.coll-toggle,
.btn.coll-toggle:hover{
  color: #EEE;
  background: #445360;
}



/* Bootstrap Buttons: Button */
/* Bootstrap Buttons: Link */
.btn-link{
  color: rgba(53, 119, 242, 1);
  border: none;
  background: transparent;
}
.btn-link.active,
.btn-link:hover,
.btn-link:focus,
.btn-link:focus:active,
.btn-link:active,
.btn-link.active:hover,
.btn-link:active:hover{
  border: none;
  background: transparent;
}
/* Bootstrap Buttons: Default */
.btn-default{
  color: #FFF;
  border: 1px solid #37b5bd;
  background: #3eced7;
}
.btn-default.active,
.btn-default:hover,
.btn-default:focus,
.btn-default:focus:active,
.btn-default:active,
.btn-default.active:hover,
.btn-default:active:hover{
  color: #FFF;
  border: 1px solid #37b5bd;
  background: #37b5bd;
}
/* Bootstrap Buttons: Primary */
.btn-primary{
  border: 1px solid rgba(53, 119, 242);
  background: rgba(53, 119, 242);
}
.btn-primary.active,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:focus:active,
.btn-primary:active,
.btn-primary.active:hover,
.btn-primary:active:hover{
  border: 1px solid rgba(53, 119, 242);
  background: rgb(27, 107, 255);
}
/* Bootstrap Buttons: Success */
.btn-success{
  border: 1px solid #00bbe1;
  background: #00cdda;
}
.btn-success.active,
.btn-success:hover,
.btn-success:focus,
.btn-success:focus:active,
.btn-success:active,
.btn-success.active:hover,
.btn-success:active:hover{
  border: 1px solid #00bbe1;
  background: #00cdda;
}
/* Bootstrap Buttons: Info */
.btn-info{
  border: 1px solid #1f71d6;
  background: #2687ff;
}
.btn-info.active,
.btn-info:hover,
.btn-info:focus,
.btn-info:focus:active,
.btn-info:active,
.btn-info.active:hover,
.btn-info:active:hover{
  border: 1px solid #1f71d6;
  background: #1f71d6;
}
/* Bootstrap Buttons: Warning */
.btn-warning{
  border: 1px solid #da7410;
  background: #ff8814;
}
.btn-warning.active,
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:focus:active,
.btn-warning:active,
.btn-warning.active:hover,
.btn-warning:active:hover{
  border: 1px solid #da7410;
  background: #da7410;
}
/* Bootstrap Buttons: Danger */
.btn-danger{
  border: 1px solid #d84838;
  background: #fd5846;
}
.btn-danger.active,
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:focus:active,
.btn-danger:active,
.btn-danger.active:hover,
.btn-danger:active:hover{
  border: 1px solid #d84838;
  background: #d84838;
}


/* Custom Labels */
.cat-marketing,
.btn-orange{
  background: #f39c12;
}
.cat-negotiations,
.btn-sky{
  background: rgba(53, 119, 242, 1);
}
.cat-management,
.btn-green{
  background: #2ecc71;
}

.btn-orange{ 
  border: 1px solid #f39c12; 
}
.btn-orange:hover{
  background: #f39c12;
  opacity: .9;
}
.btn-sky{ 
  border: 1px solid rgba(53, 119, 242, 1); 
}
.btn-sky:hover{
  background: rgba(53, 119, 242, 1);
  opacity: .9;
}
.btn-green{ 
  border: 1px solid #2ecc71; 
}
.btn-green:hover{
  background: #2ecc71;
  opacity: .9;
}
.btn-white{ 
  border: 1px solid #EEE; 
  color: #666;
  background: #FFF;
}
.btn-white:hover{
  opacity: .9;
  border: 1px solid #EEE; 
  background: #FFF;
}
  
.btn-orange:hover, .btn-sky:hover, .btn-green:hover{
  color: #FFF;
  border-color: transparent;
  opacity: .9;
}



.jumbotron{ background: rgba(68, 70, 72, .1); }

.nav-pills>li>a{
  color: #36b3bb;
  border: 1px solid #36b2ba;
  background-color: transparent;
}

.nav-pills>li.active>a,
.nav-pills>li.active>a:focus,
.nav-pills>li.active>a:hover {
  color: #fff;
  background-color: #36b3bb;
}


/* Debrief Bar */
#inner-menu.nav-pills>li>a{
  border: none;
}
#inner-menu.nav-pills>li>a:hover{
  color: #1275fa;
}
#inner-menu.nav-pills>li.active>a,
#inner-menu.nav-pills>li.active>a:focus,
#inner-menu.nav-pills>li.active>a:hover{
  color: #1275fa;
  font-weight: 600;
  border: none;
  background: transparent;
}
body{
  color: #555;
}

.menu-item{
  cursor: pointer;
}

.sec-title{
  font-weight: 300;
  padding: 10px 10px;
  background: #EEE;
  border-left: 4px solid #007bff;
}

.card-selectable:hover{
  cursor: pointer;
  background: #EEE;
  -webkit-transform: scale(1.02);
          transform: scale(1.02)
}

.card-selectable.selected{
  color: #FFF;
  background: #007bff;
}

.issue-like:hover{ cursor: pointer; }
.modal-xxl{
  width: 95%;
  max-width: 95%;
}

.side-menu{
  position: fixed;
  top: 0;
  right: 0;
  min-width: 400px;
  min-height: 100vh;
  padding: 0 20px;
  /* background: rgba(79, 37, 130, .9); */
  background: rgba(0, 123, 255, 1);
  z-index: 100;
}

.side-menu-overlay{
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,.2);
  z-index: 90;
}

.menu-close{
  color: #FFF;
  text-align: center;
  margin: 10px 10px 0 10px;
  padding: 15px 15px 5px 15px;
  font-size: 30px;
  cursor: pointer;
}

.menu-item{
  color: #444;
  text-align: left;
  margin: 20px;
  background: #FFF;
  border-radius: 10px;
  cursor: pointer;
}
.menu-item > a,
.menu-item > span{
  display: block;
  padding: 20px;
  color: inherit;
  text-decoration: none;
}

.menu-item .icon{
  float: left; 
  width: 50px;
  height: 45px;
  margin-right: 15px;
}








/* Chat bubbles */
.message small b{
  opacity: .7;
  font-weight: 300;
}
.message .bg-wharton,
.offer-box.bg-wharton{
  border: none;
  background: #004785;
  /* box-shadow: -2px 3px 5px 1px rgba(79, 37, 130, 0.2); */
  box-shadow: 0px 3px 3px 0px rgb(0,0,0,.1);
  border: 1px solid #E6E6E6;
}
.message .bg-white,
.offer-box.bg-white{
  border: none;
  background: #f9f9f9 !important;
  box-shadow: 0px 3px 3px 0px rgb(0,0,0,.1);
  border: 1px solid #E6E6E6;
}

/* ARROW */
.message .card:after, .message .card:before {
border: solid transparent;
content: " ";
height: 0;
width: 0;
position: absolute;
pointer-events: none;
}
.message .card.float-left{ border-top-left-radius: 0; }
.message .card.float-right{ border-top-right-radius: 0; }

.message .card.float-left:after {
  right: 100%;
top: 10px;
border-color: rgba(136, 183, 213, 0);
  border-top-color: #f9f9f9;
  border-right-color: #f9f9f9;
  border-width: 10px;
  margin-top: -10px;
  margin-right: -3px;
}
.message .card.float-left:before {
  right: 100%;
top: 10px;
  border-color: rgba(194, 225, 245, 0);
  border-top-color: #E6E6E6;
  border-right-color: #E6E6E6;
  border-width: 10px;
  margin-top: -11px;
}

.message .card.float-right:after {
  left: 100%;
top: 10px;
  border-color: rgba(136, 183, 213, 0);
  border-top-color: rgb(243, 244, 254);
  border-left-color: rgb(243, 244, 254);
  border-width: 10px;
  margin-top: -10px;
  margin-left: -3px;
}
.message .card.float-right:before {
  left: 100%;
top: 10px;
  border-color: rgba(194, 225, 245, 0);
  border-top-color: #E6E6E6;
  border-left-color: #E6E6E6;
  border-width: 10px;
  margin-top: -11px;
}


/* BUILDER */
.offer-box .btn-secondary {
  background: #DDD;
  border: 1px solid #DDD;
  color: #222;
  opacity: .7;
}
.offer-box .btn-secondary:hover {
  background: #D6D6D6;
  border: 1px solid #D6D6D6;
  color: #222;
  opacity: 1;
}


/* Theming */
.text-wharton{
  color:#004785;
}
.bg-wharton,
.badge-wharton{
  background: #004785;
}

.btn-wharton {
  color: #fff;
  background-color: #004785;
  border-color: #004785;
}

.btn-wharton:hover {
  color: #fff;
  background-color: #004785;
  border-color: #004785;
}

.btn-wharton:focus, .btn-wharton.focus {
  box-shadow: 0 0 0 0.2rem #004785;
}

.btn-wharton.disabled, .btn-wharton:disabled {
  color: #fff;
  background-color: #004785;
  border-color: #004785;
}

.btn-wharton:not(:disabled):not(.disabled):active, .btn-wharton:not(:disabled):not(.disabled).active,
.show > .btn-wharton.dropdown-toggle {
  color: #fff;
  background-color: #004785;
  border-color: #004785;
}

.btn-wharton:not(:disabled):not(.disabled):active:focus, .btn-wharton:not(:disabled):not(.disabled).active:focus,
.show > .btn-wharton.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #004785;
}

.btn-outline-wharton {
  color: #004785;
  border-color: #004785;
}

.btn-outline-wharton:hover {
  color: #fff;
  background-color: #004785;
  border-color: #004785;
}

.btn-outline-wharton:focus, .btn-outline-wharton.focus {
  box-shadow: 0 0 0 0.2rem #004785;
}

.btn-outline-wharton.disabled, .btn-outline-wharton:disabled {
  color: #004785;
  background-color: transparent;
}

.btn-outline-wharton:not(:disabled):not(.disabled):active, .btn-outline-wharton:not(:disabled):not(.disabled).active,
.show > .btn-outline-wharton.dropdown-toggle {
  color: #fff;
  background-color: #004785;
  border-color: #004785;
}

.btn-outline-wharton:not(:disabled):not(.disabled):active:focus, .btn-outline-wharton:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-wharton.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #004785;
}
